#root {
  @apply h-full;
  --antd-border-color: #40a9ff;
}

.ant-btn-icon-only {
  @apply flex items-center justify-center;
}

.ant-layout-sider-children {
  @apply flex flex-col;
}

.main-menu {
  @apply flex-1;
}

.ant-table-pagination.ant-pagination {
  @apply m-0 mb-4;
}

.jc-drawer .ant-drawer-body {
  @apply p-0;
  @apply flex flex-col;
}

.jc-drawer__content {
  @apply flex-1 py-4 px-6 overflow-y-auto;
}

.jc-drawer__footer {
  @apply py-4 px-6 border-t border-solid border-gray-100;
}

.ant-tag {
  @apply px-2 py-px rounded-sm;
  @apply bg-gray-200 border-gray-200;
}

.ant-tag-close-icon {
  @apply align-baseline;
}

.actions {
  @apply hidden;
}

.more-actions {
  text-align: center;
}

.ant-table-row {
  @apply relative;
}

/* TODO apply this to !fashion only as soon as we are able to detect account type */
.ant-table-row:hover .actions {
  display: flex;
  flex-direction: column;
}

.popover-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

/* TODO apply this to !fashion only as soon as we are able to detect account type */
.ant-table-row:hover .actions.editing {
  display: block;
}

/* TODO apply this to !fashion only as soon as we are able to detect account type */
.ant-table-row:hover .more-actions {
  @apply hidden;
}

.ant-form-item {
  margin: 0;
}

.delete-btn {
  @apply uppercase;
  @apply inline-flex items-center !important;
}

/*.ant-form-item-control, ant-form-item-label {*/
/*  max-width: 100% !important;*/
/*}*/

.delete-attribute-button {
  @apply text-red-500;
}

.ant-row.ant-form-item {
  @apply mb-4;
}

.ant-row.ant-form-item:last-of-type {
  @apply mb-0;
}

.ant-layout.ant-layout-has-sider {
  height: 100% !important;
  min-height: auto !important;
  overflow-y: hidden;
}

.ant-layout.site-layout {
  overflow-y: auto;
}

.ant-upload-draggable-list-item {
  height: 100%;
}

.ant-input-number {
  width: 100%;
}

.ant-table-thead {
  text-transform: uppercase;
}

.pageTitle {
  font-size: 20px;
}

.pageSection {
  font-size: 16px;
  font-weight: 500;
}

.ant-message {
  bottom: 8px !important;
}

.ant-drawer-title {
  @apply font-bold;
}

.ant-tag-geekblue {
  @apply bg-white;
  @apply uppercase;
  @apply mt-2;
  @apply w-full;
  color: #0090ff;
  border: 1px solid #0090ff;
  text-align: center;
}

.ant-card-actions {
  @apply pr-4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: none;
}

.ant-card-meta-description {
  @apply line-clamp-3;
}

.ant-upload-list-picture-card-container {
  height: auto;
}

.ant-upload-draggable-list-item {
  height: 104px;
}

.warehouse-select {
  margin-top: 30px !important;
}

.ant-form-item-has-error .ant-upload-drag {
  border-color: red !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.uploadCardType .ant-list-item-meta-description {
  @apply line-clamp-3;
}

.uploadCardType {
  @apply mb-4;
  @apply p-4;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  background: #fff;
  border-radius: 2px;
}

.uploadCardType:hover {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}

.rowLineCamp .ant-table-tbody>tr>td>div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ant-table-tbody>tr:hover>td>a {
  text-decoration: underline;
}

.py-4 .antIcon>span>span>svg {
  display: block;
  margin-left: 3px;
  margin-bottom: -1px;
}

.rowHoverCursorStyle .ant-table-tbody>tr:hover {
  @apply cursor-pointer;
}

.anticonSvg>span>svg {
  display: block;
}

.toolbarClassName {
  padding: 10px 10px 5px 10px;
  height: auto;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 0px;
}

.wrapperClassName {
  background-color: #fff;
}

.wrapperClassName:hover {
  border-color: var(--antd-border-color);
  border-width: 1px;
}

.editorClassName {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.DraftEditor-editorContainer {
  height: 230px;
}

.rdw-center-aligned-block * {
  text-align: center !important;
}

.rdw-right-aligned-block * {
  text-align: right !important;
}

.cursorTag {
  @apply cursor-pointer;
}

.editorList {
  height: 20px;
  width: 40px;
}

.listView .ant-col-4 {
  max-width: 100%;
}

.listView .ant-card-cover img {
  height: 220px;
  object-fit: cover;
}

.ant-table-body {
  overflow-y: auto !important;
}

.uploadCardType:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f,
    0 5px 12px 4px #00000017;
}

.ant-typography {
  margin-bottom: 0 !important;
}

button:disabled,
button[disabled] {
  background: #888 !important;
  color: white !important;
}

.ant-btn.ant-btn-secondary {
  background: #f0f0f0 !important;
  color: #595959 !important;
}

.ant-upload-list .ant-upload-list-item-info .ant-upload-list-item-image {
  display: block;
  height: 100%;
  width: 100%;
}

.w-100 {
  width: 100%;
}

.hover-class:hover {
  background: #f1f1f1;
}

.ant-upload-list-item-name {
  color: #0090ff;
}

.hover-toggle {
  opacity: 0;
}

.custom-table-select {
  border: 0 !important;
  box-shadow: none !important;
  padding-left: 14px;
}

.hover-class:hover .hover-toggle {
  opacity: 1;
}

.main-menu {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.main-menu::-webkit-scrollbar {
  width: 4px;
}

.main-menu::-webkit-scrollbar-thumb {
  background-color: gray;
}

.scrolling {
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow-y: auto;
  position: relative;
}

.hoverHandler {
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  background: rgba(0, 0, 0, 0.0);
  transition: all 0.3s ease-in-out;
}

.hoverIt {
  background: rgba(0, 0, 0, 0.8);
}

.customImagePreview {
  height: 102px;
  width: 100%;
  object-fit: cover;
  border: 4px solid #fff;
}

.ant-upload-list-picture-card-container {
  min-width: 104px;
}

.fixed-32-h .ant-select-dropdown {
  width: 500px !important;
  min-width: 500px !important;
}

.fixed-32-h .ant-select-dropdown .ant-select-item-option-content {
  font-size: 12px;
}

.hideTags .ant-select-selection-item-remove {
  /* display: none; */
  width: 100%;
  height: 100%;
  position: absolute;
}

.hideTags .ant-select-selection-item-remove .anticon-close {
  visibility: hidden;
}

.hideTags .ant-select-selection-item {
  padding: 0;
  border: none;
  background-color: transparent;
}

/*
.ant-select-selection-overflow-item-suffix {
  display: none;
} */

.ant-progress-inner {
  background: #fff;
  border: 1px solid #eee;
}

.side-searchbox button {
  height: 32px;
}

.side-searchbox input {
  height: 32px;
}

.food-table-name {
  color: rgb(29, 78, 216);
}

.food-table-name:hover {
  color: rgb(29, 78, 216);
}

.editable-table-menu-icon {
  color: #1890ff;
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background-color: #f2f4f6;
}

.horizontally-centered {
  text-align: center;
}

.user-info {
  display: flex;
  column-gap: 5px;
}

.align-right {
  text-align: right;
}

.coupon-products-table .ant-table-content {
  min-height: 605px;
}

.coupon-products-table .ant-table-placeholder .ant-table-cell {
  border-bottom: none;
}

.truncate-with-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.align-right {
  text-align: right;
}

.layout-main-content-and-footer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layout-main-content {
  flex: 1 0 auto;
}

.layout-footer {
  flex-shrink: 0;
}

.as-wide-as-content {
  display: inline-block;
}

.ant-input-group .ant-input.images-base-url {
  width: calc(100% - 200px);
}

/* ==== IMAGE UPLOAD, START ==== */
.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.uploaded-image-overlay {
  width: 102px;
  height: 102px;
  position: relative;
}

.uploaded-image-overlay:hover .uploaded-image {
  opacity: 0.5;
}

.uploaded-image-icon {
  display: none;
  color: black;
  position: absolute;
  z-index: 10;
  transform: translate(-50%, -50%);
  left: 51px;
}

.uploaded-image-overlay:hover .uploaded-image-icon {
  display: block;
}

.uploaded-image-icon-preview {
  top: 35px;
}

.uploaded-image-icon-edit {
  top: 65px;
  pointer-events: none;
}

/* ==== IMAGE UPLOAD, END ==== */
.ListItemMeta {
  display: flex;
}

.ListItemMeta .icon {
  display: flex;
  margin-right: 10px;
  width: 120px;
  height: 120px;
  background-color: #eee;
  font-size: 20px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}

.ListItemMeta h4 {
  margin-bottom: 0;
}

.ListItemMeta p {
  margin-top: 12px;
}

.brandsSelect .ant-select-clear {
  width: 30px;
  height: 30px;
  top: 1px;
  right: 1px;
  margin: 0;
  line-height: 25px;
}

.bottom {
  align-self: flex-end;
}

.hoverImageUploader {
  position: relative;
  cursor: pointer;
}

.hoverImageUploader img {
  width: 40px;
  height: 40px;
}

.staticImage {
  width: 40px;
  height: 40px;
}

.staticImage img {
  width: 40px;
  height: 40px;
  background-size: cover;
}

.hoverImageUploader span,
img {
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}

.hoverImageUploader span {
  opacity: 0;
  position: absolute;
  top: calc(50% - 6px);
  left: 0;
  width: 40px;
  height: 40px;
}

.hoverImageUploader:hover img {
  opacity: 0.6;
}

.hoverImageUploader:hover span {
  opacity: 1;
}

.ant-message-rtl span {
  direction: ltr !important;
}

.ProductsEditableTableCategories .ant-table-selection-column {
  padding-left: 18px;
}

.ant-layout-sider-collapsed .sidebar-username .ant-menu-title-content {
  visibility: hidden;
}


aside .ant-menu::-webkit-scrollbar {
  width: 0.75em;
  cursor: grab;
}

aside .ant-menu {
  scrollbar-width: auto;
}

aside .ant-menu {
  scrollbar-color: #ccc;
}

aside .ant-menu::-webkit-scrollbar-thumb {
  background-color: #ccc;
  cursor: grab;
}

.last-column {
  margin-left: auto;
}

.checkbox-one-line {
  padding: 8px;
  border-radius: 2px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.break-word {
  word-wrap: break-word;
}

.disabled-area {
  cursor: not-allowed;
}

.minHeight120 {
  min-height: 120px;
}

.ant-menu-inline-collapsed .localbusiness-selector {
  display: none;
}

.dual-list-selector .ant-table {
  height: calc(40vh + 39px);
}

.dual-list-selector .ant-table-placeholder .ant-table-cell {
  height: calc(30vh);
  border-bottom: none;
}

.dual-list-selector .button-margin {
  margin-left: auto;
  margin-right: 15px;
}

.dual-list-selector .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  /* Hide the vertical separator between table header cells */
  width: 0;
}

.ant-menu-item.active {
  color: #1890ff;
}

.image-upload-outfit .uploaded-image-overlay {
  min-width: 100%;
}

.image-upload-outfit .uploaded-image-overlay .ant-upload {
  width: 100%;
  height: 150px;
}

.collapsible-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
}

.collapsible-header-icon {
  display: flex;
  color: #188dfb;
  padding-left: 15px;
}

.collapsible-horizontal-line {
  border: 1px #d9d9d9 solid;
}

.collapsible-panel {
  padding-top: 15px;
}

.centered {
 display: flex;
 align-items: center;
 justify-content: center;
}

.image-upload-outfit .uploaded-image-icon {
  left: 73px;
}

.image-upload-outfit .uploaded-image-icon-preview {
  top: 55px;
}

.image-upload-outfit .uploaded-image-icon-edit {
  top: 95px;
}

.ant-upload.ant-upload-select-picture-card>.ant-upload {
  overflow: hidden;
}


.variantTableOutfits .ant-checkbox-disabled .ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 21.5%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #aaa;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  content: ' ';
}

.collapsible-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
}

.collapsible-header-icon {
  display: flex;
  color: #188dfb;
  padding-left: 15px;
}

.collapsible-horizontal-line {
  border: 1px #d9d9d9 solid;
}

.collapsible-panel {
  padding-top: 15px;
}

.linesCounter {
  background-color: #dee0e6;
}

.linesCounter pre {
  text-align: right;
  line-height: 22px;
  font-size: 12px;
  margin-bottom: 0;
}

.download-type-card .ant-card-body {
  display: flex;
  padding: 1rem;
}

.download-type-card .download-arrow-icon {
  margin-left: auto;
  align-self: center;
}

.download-icon {
  width: 120px;
  height: 120px;
  margin-right: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
}

.download-title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.ant-tag-checkable, .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: white;
  background-color: gray;
}

.ant-tag-checkable-checked {
  background-color: #108ee9;
}

.ant-row.ant-form-item.category-tag {
  display: inline-block;
  margin-bottom: 0;
}

.prefix-asterisk::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
